// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".g97Kx{padding:6px;padding-top:2px}.KHb_l{position:relative;width:100%;margin-top:4px;margin-left:auto;margin-right:auto;padding-left:6px;padding-right:6px;background:#f4f5f7;border-radius:4px}.RKsfN{max-width:100%;text-overflow:ellipsis;overflow:hidden;white-space:nowrap}.CyPwT{color:#697077}", "",{"version":3,"sources":["webpack://./src/components/assistant/chat/inputActions/fileList/FileList.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CACA,eAAA,CAGF,OACE,iBAAA,CACA,UAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,gBAAA,CACA,iBAAA,CACA,kBAAA,CACA,iBAAA,CAGF,OACE,cAAA,CACA,sBAAA,CACA,eAAA,CACA,kBAAA,CAOF,OACE,aAAA","sourcesContent":[".filesWrapper {\n  padding: 6px;\n  padding-top: 2px;\n}\n\n.file {\n  position: relative;\n  width: 100%;\n  margin-top: 4px;\n  margin-left: auto;\n  margin-right: auto;\n  padding-left: 6px;\n  padding-right: 6px;\n  background: #f4f5f7;\n  border-radius: 4px;\n}\n\n.textContainer {\n  max-width: 100%;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n}\n\n// .fileText {\n\n// }\n\n.fileSize {\n  color: #697077;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filesWrapper": "g97Kx",
	"file": "KHb_l",
	"textContainer": "RKsfN",
	"fileSize": "CyPwT"
};
export default ___CSS_LOADER_EXPORT___;
