// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eLL7x{display:flex;flex-direction:column;gap:8px;transition:width .3s ease;position:relative;z-index:2;flex-shrink:0}.BAWPU{width:254px;height:calc(100% - var(--header-height));position:absolute;left:0;background-color:var(--wpp-grey-color-000);padding:0 8px 0 12px;box-shadow:0 4px 4px 0 rgba(52,58,63,.0509803922),0 8px 12px 0 rgba(52,58,63,.1215686275);border-radius:0 0 0 12px}.SH0FP{max-height:450px}.PN_Q4{width:32px;height:1px;color:var(--wpp-grey-color-300)}.TOAKt{width:250px}.AQniy{width:100%}.UFIR6{width:32px;flex-shrink:0}", "",{"version":3,"sources":["webpack://./src/components/assistant/chat/chatSidebar/Sidebar.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,yBAAA,CACA,iBAAA,CACA,SAAA,CACA,aAAA,CAGF,OACE,WAAA,CACA,wCAAA,CACA,iBAAA,CACA,MAAA,CACA,0CAAA,CACA,oBAAA,CACA,yFAAA,CACA,wBAAA,CAGF,OACE,gBAAA,CAGF,OACE,UAAA,CACA,UAAA,CACA,+BAAA,CAGF,OACE,WAAA,CAGF,OACE,UAAA,CAGF,OACE,UAAA,CACA,aAAA","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  transition: width 0.3s ease;\n  position: relative;\n  z-index: 2;\n  flex-shrink: 0;\n}\n\n.expanded {\n  width: 254px;\n  height: calc(100% - var(--header-height));\n  position: absolute;\n  left: 0;\n  background-color: var(--wpp-grey-color-000);\n  padding: 0 8px 0 12px;\n  box-shadow: 0 4px 4px 0 #343A3F0D, 0 8px 12px 0 #343A3F1F;\n  border-radius: 0 0 0 12px;\n}\n\n.collapsed {\n  max-height: 450px;\n}\n\n.horizontalDivider {\n  width: 32px;\n  height: 1px;\n  color: var(--wpp-grey-color-300);\n}\n\n.lengthContainer {\n  width: 250px;\n}\n\n.wpp-action-button {\n  width: 100%;\n}\n\n.container {\n  width: 32px;\n  flex-shrink: 0;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "eLL7x",
	"expanded": "BAWPU",
	"collapsed": "SH0FP",
	"horizontalDivider": "PN_Q4",
	"lengthContainer": "TOAKt",
	"wpp-action-button": "AQniy",
	"container": "UFIR6"
};
export default ___CSS_LOADER_EXPORT___;
