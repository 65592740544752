// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PxTUZ{width:58px;height:68px;position:absolute;top:0;right:0}.rIxM5{position:fixed;right:52px;top:50%;width:auto;background-color:var(--wpp-grey-color-000);border-radius:var(--wpp-border-radius-l);box-shadow:0 8px 32px 0 rgba(52,58,63,.25);display:flex;flex-direction:column;align-items:center;transform:translateY(-50%);transition:all 200ms ease-in-out;padding:20px 12px 20px 12px;gap:8px;z-index:1000}.CRkWd{visibility:visible}.DkZjX{opacity:0;visibility:hidden;transition-duration:100ms}._3BtgU{background-color:#0a2fff;width:44px;box-shadow:0 4px 12px rgba(0,20,204,.3)}", "",{"version":3,"sources":["webpack://./src/components/assistant/toolbar/Toolbar.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,KAAA,CACA,OAAA,CAGF,OACE,cAAA,CACA,UAAA,CACA,OAAA,CACA,UAAA,CACA,0CAAA,CACA,wCAAA,CACA,0CAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,0BAAA,CACA,gCAAA,CACA,2BAAA,CACA,OAAA,CACA,YAAA,CAGF,OACE,kBAAA,CAGF,OACE,SAAA,CACA,iBAAA,CACA,yBAAA,CAGF,QACE,wBAAA,CACA,UAAA,CACA,uCAAA","sourcesContent":[".toolbarWrapper {\n  width: 58px;\n  height: 68px;\n  position: absolute;\n  top: 0;\n  right: 0;\n}\n\n.toolbarContainer {\n  position: fixed;\n  right: calc(44px + 8px);\n  top: 50%;\n  width: auto;\n  background-color: var(--wpp-grey-color-000);\n  border-radius: var(--wpp-border-radius-l);\n  box-shadow: 0 8px 32px 0 rgb(52 58 63 / 25%);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  transform: translateY(-50%);\n  transition: all 200ms ease-in-out;\n  padding: 20px 12px 20px 12px;\n  gap: 8px;\n  z-index: 1000;\n}\n\n.opened {\n  visibility: visible;\n}\n\n.closed {\n  opacity: 0;\n  visibility: hidden;\n  transition-duration: 100ms;\n}\n\n.toolbarMainButtonExpanded {\n  background-color: rgb(10, 47, 255);\n  width: 44px;\n  box-shadow: 0 4px 12px rgba(0, 20, 204, .3);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbarWrapper": "PxTUZ",
	"toolbarContainer": "rIxM5",
	"opened": "CRkWd",
	"closed": "DkZjX",
	"toolbarMainButtonExpanded": "_3BtgU"
};
export default ___CSS_LOADER_EXPORT___;
