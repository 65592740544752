import { AnalyticsActionType } from '@wpp-open/core'
import clsx from 'clsx'
import React, { useState } from 'react'

import AppsList from 'components/assistant/apps/AppsList'
import styles from 'components/assistant/chat/chatSidebar/Sidebar.module.scss'
import { AssistantTabs } from 'constants/ui'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS } from 'utils/events'

interface Props {
  isAiAssistantOpen?: boolean
  assistantPosition?: { x: number; y: number }
  assistantWidth?: number
  onSelectTab: (tab: AssistantTabs) => void
}

export const Sidebar = (props: Props) => {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState<boolean>(false)

  const handleMouseEnter = () => {
    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: EVENTS.ACTIONS.SIDEBAR_EXPAND,
    })
    setIsSidebarExpanded(true)
  }

  return (
    <div className={styles.container} data-testid="sidebar-container">
      <div
        className={clsx(styles.wrapper, isSidebarExpanded ? styles.expanded : styles.collapsed)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => setIsSidebarExpanded(false)}
      >
        <AppsList isSidebarExpanded={isSidebarExpanded} {...props} data-testid="apps-list" />
      </div>
    </div>
  )
}

export default Sidebar
