import { useRef, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import styles from 'components/assistant/chat/chatBubble/markdownText/MarkdownText.module.scss'

import { useContentContext } from '../../../../../hooks/useContentContext'

interface Props {
  text: string
}

export const MarkdownText = ({ text }: Props) => {
  const markdownRef = useRef<HTMLDivElement>(null)
  const { setTableWidth, setIsTableInAnswer } = useContentContext()

  useEffect(() => {
    const markdownElement = markdownRef.current
    if (markdownElement) {
      const tableElement = markdownElement.querySelector('table')
      if (tableElement) {
        setIsTableInAnswer(true)
        setTableWidth(tableElement.clientWidth)
      } else {
        setIsTableInAnswer(false)
        setTableWidth(0)
      }
    }
  }, [text, setIsTableInAnswer, setTableWidth])

  return (
    <div ref={markdownRef}>
      <ReactMarkdown data-testid="markdownText" className={styles.markdownText} remarkPlugins={[remarkGfm]}>
        {text}
      </ReactMarkdown>
    </div>
  )
}
