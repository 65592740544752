// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LAeH3{margin-bottom:24px;padding:13px 8px;background-color:var(--wpp-grey-color-700);box-shadow:var(--wpp-box-shadow-s)}.JkO0j{color:var(--wpp-grey-color-000);cursor:text}.VbBSJ{display:grid;grid-gap:24px;grid-template-rows:repeat(2, 1fr);grid-template-columns:repeat(2, 1fr)}.iG7AZ{padding:5px 12px;border:1px solid var(--wpp-grey-color-500);border-radius:var(--wpp-border-radius-round);cursor:pointer}.qAhmh{cursor:text}", "",{"version":3,"sources":["webpack://./src/components/assistant/chat/chatInstructions/ChatInstructions.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CACA,gBAAA,CACA,0CAAA,CACA,kCAAA,CAGF,OACE,+BAAA,CACA,WAAA,CAGF,OACE,YAAA,CACA,aAAA,CACA,iCAAA,CACA,oCAAA,CAGF,OACE,gBAAA,CAEA,0CAAA,CACA,4CAAA,CACA,cAAA,CAGF,OACE,WAAA","sourcesContent":[".mentionsInstructionsCard {\n  margin-bottom: 24px;\n  padding: 13px 8px;\n  background-color: var(--wpp-grey-color-700);\n  box-shadow: var(--wpp-box-shadow-s);\n}\n\n.mentionsInstructions {\n  color: var(--wpp-grey-color-000);\n  cursor: text;\n}\n\n.exampleCardsGrid {\n  display: grid;\n  grid-gap: 24px;\n  grid-template-rows: repeat(2, 1fr);\n  grid-template-columns: repeat(2, 1fr);\n}\n\n.exampleCard {\n  padding: 5px 12px;\n  // background-color: var(--wpp-primary-color-100);\n  border: 1px solid var(--wpp-grey-color-500);\n  border-radius: var(--wpp-border-radius-round);\n  cursor: pointer;\n}\n\n.selectable {\n  cursor: text;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mentionsInstructionsCard": "LAeH3",
	"mentionsInstructions": "JkO0j",
	"exampleCardsGrid": "VbBSJ",
	"exampleCard": "iG7AZ",
	"selectable": "qAhmh"
};
export default ___CSS_LOADER_EXPORT___;
