import { WppActionButton, WppSkeleton, WppTypography, WppIconChevron } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { Fragment, useCallback, useEffect, useRef, useState } from 'react'

import { useFetchHubsApi } from 'api/assistant/queries/hubs/useFetchHubsApi'
import { AssistantTabs } from 'constants/ui'
import { useAppsContext } from 'hooks/useAppsContext'
import { AppAPI } from 'types/app/app'

import { AppItem } from './appItem/AppItem'
import styles from './AppsList.module.scss'

export default function AppsList({
  assistantWidth = 440,
  isAiAssistantOpen,
  onMouseEnter,
  isSidebarExpanded,
  onSelectTab,
}: Readonly<{
  isExpanded?: boolean
  assistantWidth?: number
  isAiAssistantOpen?: boolean
  assistantPosition?: { x: number; y: number }
  onMouseEnter?: () => void
  isSidebarExpanded?: boolean
  onSelectTab?: (tab: AssistantTabs) => void
}>) {
  const [appSaved] = useState<AppAPI | null>(null)
  const { osContext } = useOs()

  const { toolbarApps } = useAppsContext()

  const { data } = useFetchHubsApi({
    params: {
      page: 1,
      sort: 'name',
    },
  })

  const osContextRef = useRef(osContext)
  const dataRef = useRef(data)
  const appSavedRef = useRef(appSaved)

  // Keep refs updated whenever props change
  useEffect(() => {
    osContextRef.current = osContext
    dataRef.current = data
    appSavedRef.current = appSaved
  }, [osContext, data, appSaved])

  const handleMessages = (event: MessageEvent) => {
    if (event.data.type === 'app-toolbar-context-share-request') {
      const iframe = document.getElementById('app-toolbar-iframe')?.querySelector('iframe')
      iframe?.contentWindow?.postMessage(
        {
          type: 'app-toolbar-context-share',
          context: osContextRef.current,
          userId: osContextRef.current.userDetails.id,
          userEmail: osContextRef.current.userDetails.email,
          userRole: 'OWNER',
          tenantId: osContextRef.current.tenant.id,
          tenantName: osContextRef.current.tenant.name,
          hubId: dataRef.current.length ? dataRef.current[0].id : '',
          hubName: dataRef.current.length ? dataRef.current[0].name : '',
          interactionType: 'tool',
          toolId: appSavedRef.current?.id,
          toolName: appSavedRef.current?.name,
        },
        '*',
      )
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleMessages)
    return () => {
      window.removeEventListener('message', handleMessages)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleMouseEnterCallback = useCallback(() => {
    if (onMouseEnter) {
      onMouseEnter()
    }
  }, [onMouseEnter])

  const showAllApps = () => {
    if (onSelectTab) {
      onSelectTab(AssistantTabs.TOOLS)
    }
  }

  return (
    <>
      {!toolbarApps &&
        Array.from({ length: 5 }).map((_, index) => (
          <div key={index}>
            <WppSkeleton width={24} height={24} animation variant="rectangle" />
          </div>
        ))}
      {toolbarApps && (
        <Fragment>
          <>
            {/* RECOMENDED */}
            {toolbarApps.recommendedApps.map(app => (
              <AppItem
                key={app.id}
                app={app}
                assistantWidth={assistantWidth}
                isSidebarExpanded={isSidebarExpanded}
                isAppPinned={toolbarApps?.pinnedApps.some(a => a.id === app.id)}
                type="recommended-apps"
                handleMouseEnterCallback={handleMouseEnterCallback}
              />
            ))}
            {/* PINNED */}
            {isAiAssistantOpen && toolbarApps.pinnedApps.length > 0 && <div className={styles.seperator} />}
            {isAiAssistantOpen &&
              toolbarApps.pinnedApps.map(app => (
                <AppItem
                  key={app.id}
                  app={app}
                  assistantWidth={assistantWidth}
                  isSidebarExpanded={isSidebarExpanded}
                  type="pinned-apps"
                  handleMouseEnterCallback={handleMouseEnterCallback}
                  isAppPinned={toolbarApps?.pinnedApps.some(a => a.id === app.id)}
                />
              ))}

            {isAiAssistantOpen && <div className={styles.seperator} />}
            {isAiAssistantOpen && (
              <WppActionButton
                className={clsx({ [styles.button]: isSidebarExpanded }, 'cancel-drag')}
                variant="secondary"
                onClick={() => showAllApps()}
                onMouseEnter={() => handleMouseEnterCallback()}
              >
                {isSidebarExpanded && (
                  <WppTypography className={styles.appName} tag="span" type="s-body">
                    All tools
                  </WppTypography>
                )}
                <WppIconChevron slot="icon-end" direction="right" />
              </WppActionButton>
            )}
          </>
        </Fragment>
      )}
    </>
  )
}
