// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".b8eY1{width:100%}.tgM5c::part(typography){color:var(--wpp-grey-color-1000)}.RdE8Z::part(typography){color:var(--wpp-grey-color-800)}.j09Zq::part(label){color:var(--wpp-grey-color-1000);font-size:14px;font-weight:400;line-height:22px}.eHqL5{display:none}", "",{"version":3,"sources":["webpack://./src/components/assistant/assistantPopover/assistantUserSettings/AssistantUserSettings.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CAIA,yBACE,gCAAA,CAKF,yBACE,+BAAA,CAKF,oBACE,gCAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CAIJ,OACE,YAAA","sourcesContent":[".mt16 {\n  width: 100%;\n}\n\n.title {\n  &::part(typography) {\n    color: var(--wpp-grey-color-1000);\n  }\n}\n\n.text {\n  &::part(typography) {\n    color: var(--wpp-grey-color-800);\n  }\n}\n\n.toggle {\n  &::part(label) {\n    color: var(--wpp-grey-color-1000);\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 22px;\n  }\n}\n\n.dNone {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mt16": "b8eY1",
	"title": "tgM5c",
	"text": "RdE8Z",
	"toggle": "j09Zq",
	"dNone": "eHqL5"
};
export default ___CSS_LOADER_EXPORT___;
