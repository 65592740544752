// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OUoVT{display:flex}.bEu5M{display:inline-flex}.IImet{flex-direction:column}.EGYQb{align-items:flex-start}.eXVWW{align-items:center}.TDc3a{align-items:flex-end}.Lg_Mu{justify-content:start}.hax2u{justify-content:center}.GIgJx{justify-content:flex-end}.sy4gy{justify-content:space-between}.bDVnG{justify-content:space-around}.BXSKR{flex-wrap:nowrap}._40LQ{flex-wrap:wrap}", "",{"version":3,"sources":["webpack://./src/components/common/flex/Flex.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CAGF,OACE,mBAAA,CAGF,OACE,qBAAA,CAGF,OACE,sBAAA,CAGF,OACE,kBAAA,CAGF,OACE,oBAAA,CAGF,OACE,qBAAA,CAGF,OACE,sBAAA,CAGF,OACE,wBAAA,CAGF,OACE,6BAAA,CAGF,OACE,4BAAA,CAGF,OACE,gBAAA,CAGF,OACE,cAAA","sourcesContent":[".root {\n  display: flex;\n}\n\n.inline {\n  display: inline-flex;\n}\n\n.column {\n  flex-direction: column;\n}\n\n.align-start {\n  align-items: flex-start;\n}\n\n.align-center {\n  align-items: center;\n}\n\n.align-end {\n  align-items: flex-end;\n}\n\n.justify-start {\n  justify-content: start;\n}\n\n.justify-center {\n  justify-content: center;\n}\n\n.justify-end {\n  justify-content: flex-end;\n}\n\n.justify-between {\n  justify-content: space-between;\n}\n\n.justify-around {\n  justify-content: space-around;\n}\n\n.nowrap {\n  flex-wrap: nowrap;\n}\n\n.wrap {\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "OUoVT",
	"inline": "bEu5M",
	"column": "IImet",
	"align-start": "EGYQb",
	"align-center": "eXVWW",
	"align-end": "TDc3a",
	"justify-start": "Lg_Mu",
	"justify-center": "hax2u",
	"justify-end": "GIgJx",
	"justify-between": "sy4gy",
	"justify-around": "bDVnG",
	"nowrap": "BXSKR",
	"wrap": "_40LQ"
};
export default ___CSS_LOADER_EXPORT___;
