// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EigVS{cursor:text}.VIYvu{cursor:pointer;width:32px;height:32px;border-radius:var(--wpp-border-radius-s)}.EYJOL{margin-bottom:8px}.WTmW3{cursor:not-allowed}.Z4zCu{margin-bottom:8px}.xA0KP:hover{background-color:var(--wpp-grey-color-300)}.MRU9u:hover{background-color:var(--wpp-grey-color-300)}.szpML:hover{background-color:var(--wpp-grey-color-300)}", "",{"version":3,"sources":["webpack://./src/components/assistant/chat/chatBubble/ChatBubble.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CAGF,OACE,cAAA,CACA,UAAA,CACA,WAAA,CACA,wCAAA,CAGF,OACE,iBAAA,CAGF,OACE,kBAAA,CAGF,OACE,iBAAA,CAGF,aACE,0CAAA,CAGF,aACE,0CAAA,CAGF,aACE,0CAAA","sourcesContent":[".selectableText {\n  cursor: text;\n}\n\n.actionButton {\n  cursor: pointer;\n  width: 32px;\n  height: 32px;\n  border-radius: var(--wpp-border-radius-s);\n}\n\n.actionRow {\n  margin-bottom: 8px;\n}\n\n.disabled {\n  cursor: not-allowed;\n}\n\n.notifyButton {\n  margin-bottom: 8px;\n}\n\n.copyButton:hover {\n  background-color: var(--wpp-grey-color-300);\n}\n\n.negativeButton:hover {\n  background-color: var(--wpp-grey-color-300);\n}\n\n.positiveButton:hover {\n  background-color: var(--wpp-grey-color-300);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectableText": "EigVS",
	"actionButton": "VIYvu",
	"actionRow": "EYJOL",
	"disabled": "WTmW3",
	"notifyButton": "Z4zCu",
	"copyButton": "xA0KP",
	"negativeButton": "MRU9u",
	"positiveButton": "szpML"
};
export default ___CSS_LOADER_EXPORT___;
