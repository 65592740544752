import { WppActionButton, WppIconSend, WppTooltip, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { memo, useEffect, useState } from 'react'

import { useFileUploadUrls } from 'api/assistant/queries/files/useFileUploadUrls'
import { useConversationContext } from 'hooks/useConversationContext'
import { useFileUploadContext } from 'hooks/useFileUploadContext'
import { useToast } from 'hooks/useToast'

// import { FileUpload } from './fileUpload/FileUpload'
import styles from './InputActions.module.scss'
import { charLength } from '../../../../constants/ui'
import { Flex } from '../../../common/flex/Flex'

interface InputActionsProps {
  question: string
  questionCharCount: number
  answerIsLoading: boolean
}

const questionMaxLength = charLength.QUESTION_MAX_LENGTH

const InputActions = ({ question, questionCharCount, answerIsLoading }: InputActionsProps) => {
  const [sendButtonColor, setSendButtonColor] = useState('var(--wpp-grey-color-600)')

  const { conversation, updateConversation } = useConversationContext()
  const { filesToUpload, setFilesToUpload, handleSubmitQuestion, setFilesLoading } = useFileUploadContext()

  const [{ data: fileUploadUrls }] = useFileUploadUrls()
  const { showToast } = useToast()

  useEffect(() => {
    const color =
      questionCharCount > 0 && questionCharCount <= questionMaxLength ? '#0014CC' : 'var(--wpp-grey-color-600)'
    setSendButtonColor(color)
  }, [questionCharCount])

  // const handleFileUploadChange = async (event: any) => {
  //   if (setFilesToUpload === undefined) return
  //   const files = event.target.files

  //   const allFiles = filesToUpload
  //     ? [
  //         ...filesToUpload,
  //         ...(Object.entries(files) as any[]).map(([, value]) => {
  //           return value
  //         }),
  //       ]
  //     : (Object.entries(files) as any[]).map(([, value]) => {
  //         return value
  //       })

  //   if (allFiles.length > 10) {
  //     showToast({
  //       message: 'You can only upload up to 10 files at a time.',
  //       type: 'error',
  //       duration: 4000,
  //     })
  //     return
  //   }

  //   setFilesToUpload(allFiles)

  //   event.target.value = null
  // }

  useEffect(() => {
    if (fileUploadUrls && fileUploadUrls.urls.length > 0 && filesToUpload?.length) {
      // if (!conversation || conversation?.ragFiles) {
      uploadFiles()
      // }
    } else {
      if (setFilesToUpload === undefined) return
      setFilesToUpload([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadUrls])

  const uploadFiles = async () => {
    setFilesLoading(true)
    if (filesToUpload === undefined) return

    const files = filesToUpload.map(file => new Blob([file], { type: file.type }))
    if (files.length !== fileUploadUrls?.urls.length) {
      return
    }

    try {
      const uploadPromises = fileUploadUrls?.urls.map(async (fileUrl, idx) => {
        const file = files[idx]

        const res = await fetch(fileUrl.url, {
          headers: {
            'Content-Type': file.type,
          },
          body: file,
          method: 'PUT',
        })

        if (res.status !== 200) {
          clearFile(idx)
          showToast({
            message: 'Error uploading file, please try again later.',
            type: 'error',
            duration: 4000,
          })
        }
      })

      // Wait for all uploads to complete
      await Promise.all(uploadPromises)
      if (conversation) {
        updateConversation(conversationDraft => {
          if (!conversationDraft || !conversationDraft.ragFiles) return
          conversationDraft.ragFiles.push(...filesToUpload.map(file => file.name))
        })
      }
      handleSubmitQuestion()
      setFilesLoading(false)
    } catch (e) {
      console.error('Error uploading files, please try again later', e)
      setFilesLoading(false)
    }
  }

  const clearFile = (idx: number) => {
    if (setFilesToUpload === undefined || filesToUpload === undefined) return

    setFilesToUpload(filesToUpload.filter((el, index) => index !== idx))
  }

  const SubmitButton = memo(() => (
    <WppActionButton
      type="submit"
      className={clsx(styles.submitQuestionButton, 'cancel-drag')}
      disabled={answerIsLoading || questionCharCount === 0 || questionCharCount > questionMaxLength}
    >
      <WppIconSend size="m" color={sendButtonColor} />
    </WppActionButton>
  ))

  return (
    <div className={styles.wrapper}>
      <Flex justify="end" align="center" className={styles.inputActions}>
        {/* Remove rag file upload */}
        {/* <FileUpload
          disabled={filesToUpload?.length !== undefined && filesToUpload?.length >= 10}
          handleFileUploadChange={handleFileUploadChange}
          tooltipText="You can only upload up to 10 files at a time"
        /> */}

        <Flex align="center">
          <WppTypography
            type="xs-body"
            className={clsx(
              question.length > questionMaxLength
                ? styles.questionCharacterLimitLabelError
                : styles.questionCharacterLimitLabel,
            )}
          >
            &nbsp;{questionCharCount}/{questionMaxLength}
          </WppTypography>

          {questionCharCount === 0 ? (
            <WppTooltip text="Write something to send a message.">
              <SubmitButton />
            </WppTooltip>
          ) : (
            <SubmitButton />
          )}
        </Flex>
      </Flex>
    </div>
  )
}

export default InputActions
