// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".F7iEa{margin-top:8px;margin-bottom:8px}.HoYJS{margin-top:4px}.Cq6qJ{color:var(--wpp-grey-color-800)}.KJBzh{color:var(--wpp-danger-color-500)}", "",{"version":3,"sources":["webpack://./src/components/assistant/chat/chatBubble/feedbackForm/FeedbackForm.module.scss"],"names":[],"mappings":"AAAA,OACE,cAAA,CACA,iBAAA,CAGF,OACE,cAAA,CAGF,OACE,+BAAA,CAGF,OACE,iCAAA","sourcesContent":[".my8 {\n  margin-top: 8px;\n  margin-bottom: 8px;\n}\n\n.questionCharacterLimit {\n  margin-top: 4px;\n}\n\n.questionCharacterLimitLabel {\n  color: var(--wpp-grey-color-800);\n}\n\n.questionCharacterLimitLabelError {\n  color: var(--wpp-danger-color-500);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"my8": "F7iEa",
	"questionCharacterLimit": "HoYJS",
	"questionCharacterLimitLabel": "Cq6qJ",
	"questionCharacterLimitLabelError": "KJBzh"
};
export default ___CSS_LOADER_EXPORT___;
