import { WppIconFile, WppTypography } from '@platform-ui-kit/components-library-react'

import { ReactComponent as IconRectangle } from 'assets/icons/rectangle.svg'
import styles from 'components/assistant/chat/chatBubble/fileAnswer/FileAnswer.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { FileAnswerDto } from 'types/dto/ConversationDto'

import { FileContent } from './FileContent'

interface Props {
  answer: FileAnswerDto
}

export const FileAnswer = ({ answer }: Props) => {
  return (
    <Flex align="start">
      <IconRectangle className={styles.rectangle} />
      <div className={styles.card}>
        {answer.content.common_description && (
          <WppTypography type="2xs-strong" className={styles.documentType}>
            {answer.content.common_description}
          </WppTypography>
        )}

        <Flex direction="column" gap={8}>
          {answer.content.resources.map((document, idx) => (
            <Flex gap={12} className={styles.file} key={`${document.title}-${idx}`}>
              <Flex>
                {document.thumbnail_url ? (
                  <img src={document.thumbnail_url} alt={document.title} className={styles.articleImg} />
                ) : document.type === 'file' ? (
                  <WppIconFile />
                ) : null}
              </Flex>

              <FileContent
                type={document.type}
                name={document.title}
                description={document.description}
                tags={document.tags}
                url={document.url}
              />
            </Flex>
          ))}
        </Flex>
      </div>
    </Flex>
  )
}
