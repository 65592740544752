import { AnalyticsActionType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import React, { FormEvent, useEffect, useMemo, useRef, useState } from 'react'

import { usePostInteractionEvent } from 'api/assistant/mutations/events/usePostInteractionEvent'
import { useFetchConversationMessages } from 'api/assistant/queries/conversation/useFetchConversationMessages'
import { useFileUploadUrls } from 'api/assistant/queries/files/useFileUploadUrls'
import { useFetchTaskProgress } from 'api/assistant/queries/polling/useFetchTaskProgress'
import { ChatProvider } from 'contexts/ChatContext'
import { FileUploadProvider } from 'contexts/FileUploadContext'
import { useAssistant } from 'hooks/useAssistant'
import { useConversationContext } from 'hooks/useConversationContext'
import { useHubsContext } from 'hooks/useHubsContext'
import { useMentions } from 'hooks/useMentions'
import { QuestionDto, ConversationMessageDto, FeedbackDto } from 'types/dto/ConversationDto'
import { StepEventDto, SubtaskStepDto } from 'types/dto/TaskDto'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS } from 'utils/events'
import { answerIsLoadingSignal, clearConversationSignal, clearInputFieldSignal } from 'utils/signals'

import { Chat } from './Chat'
import { setCursorToEnd } from './utils/utils'
import { charLength } from '../../../constants/ui'
import { useContentContext } from '../../../hooks/useContentContext'

const NEW_CONVERSATION_FLAG = 'newConversationFlag'
const QUESTION_MAX_LENGTH = charLength.QUESTION_MAX_LENGTH

export const ChatWrapper = () => {
  const { conversation, updateConversation } = useConversationContext()
  const { selectedTabOuter, isAiAssistantOpen } = useContentContext()
  const { osContext } = useOs()
  const [activeTaskId, setActiveTaskId] = useState('')
  const { mentionOptions, findMentionUsed } = useMentions()

  const [filesToUpload, setFilesToUpload] = useState<File[]>([])
  const [filesLoading, setFilesLoading] = useState<boolean>(false)
  const inputRef = useRef<HTMLDivElement>(null)
  const [question, setQuestion] = useState('')
  const [questionCharCount, setQuestionCharCount] = useState(0)
  const [firstQuestion, setFirstQuestion] = useState('')
  const [conversationIdOfLoadingAnswer, setConversationIdOfLoadingAnswer] = useState<string | null>(null)
  const [showTokenLimitReached, setShowTokenLimitReached] = useState(false)
  const [answerError, setAnswerError] = useState(false)
  const [messageId, setMessageId] = useState<string | undefined>(undefined)
  const [feedbackType, setFeedbackType] = useState<string | undefined>(undefined)
  const defaultValue = useRef('')
  const mentionsContainerRef = useRef<HTMLDivElement>(null)
  const mentionsRef = useRef({} as any)
  const refScrollBottomDiv = useRef<HTMLDivElement>(null)
  const [mentionDropdownVisible, setMentionDropdownVisible] = useState(false)
  const [activeMentionId, setActiveMentionId] = useState('')
  const [mentionOptionsFiltered, setMentionOptionsFiltered] = useState(mentionOptions)
  const [chatInitiatedTab, setChatInitiatedTab] = useState('')

  const initiateChat = (tab: string) => {
    setChatInitiatedTab(tab)
  }

  const [listOfAllSteps, setListOfAllSteps] = useState<SubtaskStepDto[]>([])
  const [listOfCurrentSteps, setListOfCurrentSteps] = useState<StepEventDto[]>([])

  const { startConversation, askQuestion } = useAssistant()

  const { mutateAsync: createEvent } = usePostInteractionEvent()
  const { hubs: hubsData } = useHubsContext()

  const [{ data: fileUploadUrls }, generateFileUploadUrls] = useFileUploadUrls()

  const { data: activeConversationMessages, isFetching: isFetchingConversationMessages } = useFetchConversationMessages(
    {
      enabled: !!conversation?.id,
      params: { itemsPerPage: 1000, chatId: conversation?.id || '' },
    },
  )

  const { data: activeTaskProgress } = useFetchTaskProgress({
    enabled: activeTaskId.length > 0,
    refetchInterval: 1000,
    refetchIntervalInBackground: true, // Stop polling when the browser tab is inactive
    params: { taskId: activeTaskId },
  })

  // On mount
  useEffect(() => {
    clearInputFieldSignal.subscribe(v => {
      if (v) {
        setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.innerHTML = ''
            inputRef?.current?.focus()
            setCursorToEnd(inputRef?.current)
            setQuestion('')
            setQuestionCharCount(0)
          }
        }, 10)
      }
    })

    clearConversationSignal.subscribe(v => {
      if (v) {
        setTimeout(() => {
          setConversationIdOfLoadingAnswer(null)
          setFirstQuestion('')
          setAnswerError(false)
        }, 10)
      }
    })
  }, [])

  useEffect(() => {
    setQuestion('')
    setQuestionCharCount(0)
  }, [selectedTabOuter])

  useEffect(() => {
    updateConversation(draft => {
      if (!draft) return
      draft.messages = activeConversationMessages
    })
  }, [activeConversationMessages, updateConversation])

  // Task progress
  useEffect(() => {
    setListOfCurrentSteps(activeTaskProgress.stepEvents)

    if (
      (activeTaskProgress.taskEvent &&
        activeTaskProgress.taskEvent.result &&
        activeTaskProgress.stepEvents.length === listOfAllSteps.length) ||
      activeTaskProgress?.taskEvent?.error ||
      activeTaskProgress?.taskEvent?.result?.length
    ) {
      if (activeTaskProgress.stepEvents.length !== listOfAllSteps.length) {
        const diff = Math.abs(listOfAllSteps.length - activeTaskProgress.stepEvents.length)
        const emptySteps = Array(diff).fill({} as StepEventDto)
        setListOfCurrentSteps([...activeTaskProgress.stepEvents, ...emptySteps])
      }
      setTimeout(() => {
        if (activeTaskProgress.taskEvent && activeTaskProgress.taskEvent.result) {
          setActiveTaskId('')
          setConversationIdOfLoadingAnswer(null)
          setFirstQuestion('')

          updateConversation(conversationDraft => {
            if (activeTaskProgress?.taskEvent?.result) {
              if (conversationDraft?.messages?.length) {
                if (activeTaskProgress.taskEvent?.result?.[0].chatId === conversationDraft.messages[0].chatId) {
                  conversationDraft.messages = [
                    ...conversationDraft.messages,
                    ...removeQuestionsFromMessages(activeTaskProgress.taskEvent.result),
                  ]
                }
              } else if (conversationDraft?.messages) {
                conversationDraft.messages = [...conversationDraft.messages, ...activeTaskProgress.taskEvent.result]
              }
            }
          })
        }
      }, 1000)
    }

    if (inputRef.current) setCursorToEnd(inputRef.current as HTMLElement)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTaskProgress])

  const answerIsLoading = useMemo(() => {
    if (
      (conversationIdOfLoadingAnswer === conversation?.id ||
        conversationIdOfLoadingAnswer === NEW_CONVERSATION_FLAG ||
        firstQuestion.length > 0 ||
        activeTaskId.length !== 0) &&
      !answerError
    ) {
      answerIsLoadingSignal.value = true
      return true
    } else {
      answerIsLoadingSignal.value = false
      return false
    }
  }, [conversationIdOfLoadingAnswer, conversation?.id, firstQuestion.length, activeTaskId.length, answerError])

  const refBubbles = useRef({} as any)

  useEffect(() => {
    if (inputRef.current) {
      if (!isAiAssistantOpen) {
        setQuestion('')
        setQuestionCharCount(0)
        if (inputRef.current) {
          inputRef.current.innerText = ''
          inputRef.current.blur()
        }
      } else {
        inputRef.current.blur()
      }
    }
  }, [isAiAssistantOpen])

  const removeQuestionsFromMessages = (messages: ConversationMessageDto[]) => {
    return messages.filter((message: any) => message.role !== 'user')
  }

  const onSubmitQuestion = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()

    onSubmitWrapper()
  }

  const onSubmitWrapper = () => {
    initiateChat(selectedTabOuter)

    if (filesToUpload && filesToUpload.length > 0) {
      try {
        generateFileUploadUrls({
          prefix: fileUploadUrls?.prefix,
          file_names: (Object.entries(filesToUpload) as any[]).map(([, value]) => {
            return value.name
          }),
        })
        // Handle submit in InputActions file after files are uploaded
      } catch (e) {
        console.error('Error uploading file:', e)
      }
    } else {
      handleSubmitQuestion()
    }
  }

  const handleSubmitQuestion = async () => {
    const questionWithoutHtml = question.replace(/<\/?[^>]+(>|$)/g, '')
    const formattedQuestion = questionWithoutHtml.replace(/\*/g, '')
    if (!formattedQuestion.trim() || formattedQuestion.length > QUESTION_MAX_LENGTH) return

    if (inputRef?.current?.innerHTML !== undefined) {
      inputRef.current.innerHTML = ''
    }

    setConversationIdOfLoadingAnswer(conversation?.id ? conversation.id : NEW_CONVERSATION_FLAG)
    const questionCopy = formattedQuestion
    setQuestion('')
    setQuestionCharCount(0)
    setAnswerError(false)
    setMessageId(undefined)
    try {
      if (!conversation) {
        setFirstQuestion(questionCopy)

        const mentionUsed = findMentionUsed(questionCopy)
        trackAnalytics({
          type: AnalyticsActionType.action,
          payload: {
            action: EVENTS.ACTIONS.MESSAGE_SENT,
            params: [
              { key: 'newConversation', value: 'true' },
              { key: 'mentionUse', value: mentionUsed ? 'true' : 'false' },
              { key: 'mention', value: mentionUsed ? `@${mentionUsed.id}` : '' },
            ],
          },
        })

        const newConversation = await startConversation({ question: questionCopy, filesPrefix: fileUploadUrls?.prefix })
        if (typeof newConversation === 'number') {
          setAnswerError(true)
          if (newConversation === 429) {
            setShowTokenLimitReached(true)
          }
          return
        }
        if (newConversation && newConversation.scheduledTask.taskId) {
          sendMessageEvent(questionCopy, newConversation.scheduledTask.taskId)

          setActiveTaskId(newConversation.scheduledTask.taskId)
          const allStepsArrays = [...newConversation.scheduledTask.subtasks.map(obj => obj.steps)]
          setListOfAllSteps(allStepsArrays.flat())
          updateConversation(newConversation.chat)
          setFilesToUpload([])
        }
        return
      }

      const questionMessage = {
        content: questionCopy,
        role: 'user',
        type: 'prompt',
        chatId: conversation.id,
      } as QuestionDto

      updateConversation(conversationDraft => {
        if (!conversationDraft) return
        conversationDraft.messages.push(questionMessage)
      })

      const mentionUsed = findMentionUsed(questionCopy)
      trackAnalytics({
        type: AnalyticsActionType.action,
        payload: {
          action: EVENTS.ACTIONS.MESSAGE_SENT,
          params: [
            { key: 'newConversation', value: 'false' },
            { key: 'mentionUse', value: mentionUsed ? 'true' : 'false' },
            { key: 'mention', value: mentionUsed ? `@${mentionUsed.id}` : '' },
          ],
        },
      })

      const answerResponse = await askQuestion({ conversationId: conversation.id, question: questionCopy })
      if (typeof answerResponse === 'number') {
        if (answerResponse === 429) {
          setShowTokenLimitReached(true)
        }
        setAnswerError(true)
        return
      }
      if (answerResponse && answerResponse.taskId) {
        sendMessageEvent(questionCopy, answerResponse.taskId)

        setActiveTaskId(answerResponse.taskId)

        const allStepsArrays = [...answerResponse.subtasks.map(obj => obj.steps)]
        setListOfAllSteps(allStepsArrays.flat())
        setFilesToUpload([])
      }
    } catch (err) {
      console.error(err)
      setAnswerError(true)
    } finally {
      setConversationIdOfLoadingAnswer(null)
    }
  }

  const sendMessageEvent = async (questionCopy: string, taskId: string) => {
    const mentionUsed = findMentionUsed(questionCopy)
    try {
      // Delete unnecessary data from osContext
      const cloneOsContext = JSON.parse(JSON.stringify(osContext))
      delete cloneOsContext.navigationTree
      delete cloneOsContext.theme
      createEvent({
        context: cloneOsContext,
        userId: osContext.userDetails.id,
        userEmail: osContext.userDetails.email,
        userRole: 'OWNER',
        tenantId: osContext.tenant.id,
        tenantName: osContext.tenant.name,
        hubId: hubsData.length ? hubsData[0].id : '',
        hubName: hubsData.length ? hubsData[0].name : '',
        interactionType: 'message',
        messageMention: mentionUsed ? `@${mentionUsed.id}` : '',
        messagePrompt: questionCopy,
        taskId: taskId,
      })
    } catch (e) {
      console.error(e)
    }
  }

  const onFeedbackUpdate = (feedback: FeedbackDto) => {
    setMessageId(feedback.messageId)
    updateConversation(draft => {
      if (!draft) return
      draft.messages = draft.messages.map(item => (item.id === feedback.messageId ? { ...item, feedback } : item))
    })
  }

  // TODO seperate values into different contexts depending on their usage/components
  const chatValues = {
    refScrollBottomDiv: refScrollBottomDiv,
    refBubbles: refBubbles,
    inputRef: inputRef,
    mentionsRef: mentionsRef,
    mentionsContainerRef: mentionsContainerRef,

    initTab: chatInitiatedTab,
    firstQuestion: firstQuestion,
    question: question,
    setQuestion: setQuestion,
    defaultValue: defaultValue.current,
    questionCharCount: questionCharCount,
    setQuestionCharCount: setQuestionCharCount,

    enableEdit: !answerIsLoading,
    answerIsLoading: answerIsLoading,
    answerError: answerError,
    isFetchingConversationMessages: isFetchingConversationMessages,
    showTokenLimitReached: showTokenLimitReached,

    conversationMessages: conversation?.messages || activeConversationMessages,
    listOfAllSteps: listOfAllSteps,
    listOfCurrentSteps: listOfCurrentSteps,

    messageId: messageId,
    feedbackType: feedbackType,

    activeMentionId: activeMentionId,
    mentionOptionsFiltered: mentionOptionsFiltered,
    mentionDropdownVisible: mentionDropdownVisible,
    setMentionDropdownVisible: setMentionDropdownVisible,
    setActiveMentionId: setActiveMentionId,
    setMentionOptionsFiltered: setMentionOptionsFiltered,

    onFeedbackUpdate: onFeedbackUpdate,
    setFeedbackType: setFeedbackType,
    onSubmitQuestion: onSubmitQuestion,
    handleSubmitQuestion: handleSubmitQuestion,
    onSubmitWrapper: onSubmitWrapper,
  }

  const fileUploadValues = {
    filesToUpload,
    setFilesToUpload,
    handleSubmitQuestion,
    filesLoading,
    setFilesLoading,
  }

  return (
    <ChatProvider value={chatValues}>
      <FileUploadProvider value={fileUploadValues}>
        <Chat />
      </FileUploadProvider>
    </ChatProvider>
  )
}
