import { WppSpinner, WppInlineMessage } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styles from 'components/assistant/chat/Chat.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { useChatContext } from 'hooks/useChatContext'
import { useConversationContext } from 'hooks/useConversationContext'

import { AnswerLoadingPlaceholder } from './answerLoadingPlaceholder/AnswerLoadingPlaceholder'
import { ChatBottomText } from './chatBottomText/ChatBottomText'
import { ChatBubble } from './chatBubble/ChatBubble'
import { Question } from './chatBubble/question/Question'
import { TextAnswer } from './chatBubble/textAnswer/TextAnswer'
import { ChatInstructions } from './chatInstructions/ChatInstructions'
import { useContentContext } from '../../../hooks/useContentContext'
import { manuallySetAssistantSize, resetAssistantSize } from '../../../utils/signals'

interface Props {
  handlePromptClick: (prompt: string) => void
  scrollToView: (id?: string) => void
}

const ChatDisplay = ({ handlePromptClick, scrollToView }: Props) => {
  const { t } = useTranslation()
  const { conversation } = useConversationContext()
  const {
    refScrollBottomDiv,
    refBubbles,
    question,
    firstQuestion,
    initTab,
    isFetchingConversationMessages,
    answerIsLoading,
    answerError,
    showTokenLimitReached,
    conversationMessages,
    listOfAllSteps,
    listOfCurrentSteps,
    setFeedbackType,
    onFeedbackUpdate,
  } = useChatContext()

  const isFirstContentEmpty = conversationMessages.length > 0 && conversationMessages[0].role === 'system'

  const conversationMessagesFiltered = isFirstContentEmpty ? conversationMessages.slice(1) : conversationMessages
  const [showLoader, setShowLoader] = useState(true)
  const currentTab = useConversationContext().tab
  const {
    isTableInAnswer,
    tableWidth,
    assistantSize,
    setAssistantSize,
    assistantPosition,
    setAssistantPosition,
    isAiAssistantOpen,
    newChatWidth,
    setNewChatWidth,
  } = useContentContext()
  const chatRef = useRef<HTMLDivElement>(null)
  const [chatWidth, setChatWidth] = useState<number>(0)
  const [chatStyle, setChatStyle] = useState<{ width: string }>({ width: '100%' })
  const [biggestTableWidth, setBiggestTableWidth] = useState<number>(0)

  useEffect(() => {
    initTab && initTab !== currentTab ? setShowLoader(false) : setShowLoader(true)
  }, [currentTab, initTab])

  useEffect(() => {
    if (chatRef.current) {
      setChatWidth(chatRef.current.getBoundingClientRect().width)
    }
  }, [tableWidth])

  useEffect(() => {
    if (isTableInAnswer) {
      resetAssistantSize.value = false
      if (chatWidth + (chatWidth - tableWidth) * -1 + 36 > biggestTableWidth) {
        setNewChatWidth(chatWidth + (chatWidth - tableWidth) * -1 + 36)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTableInAnswer, tableWidth, chatWidth, biggestTableWidth])

  useEffect(() => {
    if (newChatWidth > parseInt(assistantSize?.width?.toString()?.replace('px', ''), 10) && !resetAssistantSize.value) {
      const newWidth = newChatWidth + 96
      const adjustedWidth = newWidth > window.innerWidth ? window.innerWidth - 48 : newWidth

      if (newWidth > biggestTableWidth) {
        setBiggestTableWidth(adjustedWidth)
      }

      if (!manuallySetAssistantSize.value) {
        setAssistantSize({ width: `${adjustedWidth}px`, height: assistantSize?.height })
        setAssistantPosition({
          x: window.innerWidth - adjustedWidth - 24,
          y: assistantPosition?.y,
        })
      }
    }
  }, [
    assistantPosition?.y,
    assistantSize?.height,
    assistantSize?.width,
    biggestTableWidth,
    newChatWidth,
    setAssistantPosition,
    setAssistantSize,
  ])

  useEffect(() => {
    const assistantWidth = parseInt(assistantSize?.width?.toString()?.replace('px', ''), 10)
    if (newChatWidth > 0 && assistantWidth <= newChatWidth && isAiAssistantOpen && !resetAssistantSize.value) {
      setChatStyle({ width: `${newChatWidth}px` })
    } else {
      setChatStyle({ width: '100%' })
    }
  }, [assistantSize?.width, newChatWidth, isAiAssistantOpen])

  return (
    <div className={clsx(styles.chatWrapper, 'cancel-drag')}>
      {isFetchingConversationMessages && conversationMessages.length < 1 && !firstQuestion && (
        <Flex className={styles.chat} justify="center" align="center">
          <WppSpinner size="m" />
        </Flex>
      )}
      {(!isFetchingConversationMessages || firstQuestion || conversationMessages.length > 0) && (
        <div className={styles.chat} style={chatStyle} ref={chatRef}>
          {!firstQuestion && !conversation && (
            <ChatInstructions onPromptClick={handlePromptClick} question={question} />
          )}
          {firstQuestion && currentTab !== 'HISTORY' && (
            <div className="cancel-drag">
              <Question text={firstQuestion} />
            </div>
          )}
          {conversationMessagesFiltered.map((message, index) => {
            if (message.type === 'failure') return <TextAnswer text={t('assisstant_errors.no_answer')} isError />
            else
              return (
                <div key={index} ref={(refBubbles.current[message.id] ??= { current: null })}>
                  <ChatBubble
                    message={message}
                    onOpenFeedback={() => scrollToView(message.id)}
                    onFeedbackUpdate={onFeedbackUpdate}
                    setFeedbackType={setFeedbackType}
                  />
                </div>
              )
          })}
          {answerIsLoading && showLoader && (
            <AnswerLoadingPlaceholder allSteps={listOfAllSteps} finishedSteps={listOfCurrentSteps} />
          )}
          {answerError && <WppInlineMessage size="s" message={t('question_input.errors.general')} type="error" />}
          <ChatBottomText tokenLimitReached={showTokenLimitReached} />
          <div ref={refScrollBottomDiv} />
        </div>
      )}
    </div>
  )
}

export default ChatDisplay
