import * as Icons from '@platform-ui-kit/components-library-react'
import { WppIconApp } from '@platform-ui-kit/components-library-react'

type IconsMap = Record<string, React.ComponentType<any>>

const iconsMap = Icons as IconsMap

export default function DynamicIconApp({
  name,
  width,
  height,
  slot,
}: Readonly<{ name: string; width?: number; height?: number; slot?: string }>) {
  if (!name.startsWith('WppIcon')) {
    return null
  }
  const IconComponent = iconsMap[name]

  if (IconComponent) {
    return <IconComponent color="#4d5358" width={width} height={height} slot={slot} />
  }
  return <WppIconApp color="#4d5358" width={width} height={height} slot={slot} />
}
