// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XOLCJ{height:calc(100% - var(--header-height));word-break:normal;border-radius:12px;padding:0 0 20px 12px;display:flex;gap:12px}.g9m4L{height:calc(100% - var(--header-height))}.u_qwK{width:1px;height:100%;background-color:var(--wpp-grey-color-300)}.FUkkL{width:32px;height:32px;position:absolute;bottom:4px;left:4px}.kg2G5{width:100%}", "",{"version":3,"sources":["webpack://./src/components/assistant/assistantPopover/assistantContent/AssistantContent.module.scss"],"names":[],"mappings":"AAAA,OACE,wCAAA,CACA,iBAAA,CACA,kBAAA,CACA,qBAAA,CACA,YAAA,CACA,QAAA,CAGF,OACE,wCAAA,CAGF,OACE,SAAA,CACA,WAAA,CACA,0CAAA,CAGF,OACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,UAAA,CACA,QAAA,CAGF,OACE,UAAA","sourcesContent":[".container {\n  height: calc(100% - var(--header-height));\n  word-break: normal;\n  border-radius: 12px;\n  padding: 0 0 20px 12px;\n  display: flex;\n  gap: 12px;\n}\n\n.spinnerContainer {\n  height: calc(100% - var(--header-height));\n}\n\n.verticalDivider {\n  width: 1px;\n  height: 100%;\n  background-color: var(--wpp-grey-color-300);\n}\n\n.iconContainer {\n  width: 32px;\n  height: 32px;\n  position: absolute;\n  bottom: 4px;\n  left: 4px;\n}\n\n.wFull {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "XOLCJ",
	"spinnerContainer": "g9m4L",
	"verticalDivider": "u_qwK",
	"iconContainer": "FUkkL",
	"wFull": "kg2G5"
};
export default ___CSS_LOADER_EXPORT___;
