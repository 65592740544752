import {
  WppTypography,
  WppListItem,
  WppTooltip,
  WppSpinner,
  WppIconInfo,
  WppActionButton,
} from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import { AnalyticsActionType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'

import { useUpdateApp } from 'api/assistant/mutations/apps/useUpdateApp'
import { usePostInteractionEvent } from 'api/assistant/mutations/events/usePostInteractionEvent'
import { useFetchHubsApi } from 'api/assistant/queries/hubs/useFetchHubsApi'
import IconPin from 'assets/icons/pin.svg'
import IconUnpin from 'assets/icons/unpin.svg'
import { Flex } from 'components/common/flex/Flex'
import DynamicIconApp from 'components/common/icons/DynamicIconApp'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { AppAPI } from 'types/app/app'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS } from 'utils/events'
import { tooltipConfig } from 'utils/tooltipConfig'

import styles from './AppItem.module.scss'

export interface Props {
  app: AppAPI
  assistantWidth: number
  isSidebarExpanded?: boolean
  isAppPinned: boolean
  type: 'all-apps' | 'recommended-apps' | 'pinned-apps'
  handleMouseEnterCallback?: () => void
}

export const AppItem = ({
  app,
  isSidebarExpanded = false,
  assistantWidth,
  isAppPinned,
  type,
  handleMouseEnterCallback,
}: Props) => {
  const { osApi, osContext } = useOs()
  const queryClient = useQueryClient()
  const { showToast } = useToast()

  // const { toolbarApps } = useApps()

  const { mutateAsync: createEvent } = usePostInteractionEvent()
  const { mutateAsync: updateApp, isLoading: loading } = useUpdateApp()
  const { data, isFetched } = useFetchHubsApi({
    params: {
      page: 1,
      sort: 'name',
    },
  })

  const closeApp = () => {
    osApi.navigation.openCompactApp({
      name: app.name,
      url: '',
      overlayProps: {
        id: 'app-toolbar-iframe',
        bounds: 'parent',
        // enableUserSelectHack: false,
        default: {
          width: 0,
          height: 0,
        },
        style: {
          display: 'none',
        },
        enableResize: app.resizable || false,
        cancel: '.cancel-drag',
      },
    })
  }

  // Close app if it's saved in the project context and the project is closed
  useEffect(() => {
    if (appSaved?.id === app.id) {
      if (!osContext.project && app.inProjectContext) {
        closeApp()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.inProjectContext, osContext.project])

  const pinApp = async () => {
    if (type === 'pinned-apps') {
      trackAnalytics({
        type: AnalyticsActionType.action,
        payload: {
          action: EVENTS.ACTIONS.APP_MENU_UNPIN,
          params: [{ key: 'productName', value: app.name }],
        },
      })
    } else if (type === 'all-apps') {
      trackAnalytics({
        type: AnalyticsActionType.action,
        payload: {
          action: appPinned ? EVENTS.ACTIONS.APP_ALL_TOOLS_UNPIN : EVENTS.ACTIONS.APP_PIN,
          params: [{ key: 'productName', value: app.name }],
        },
      })
    }

    try {
      await updateApp({
        pin: !appPinned,
        tenantId: osContext.tenant.id,
        appId: app.id,
        userId: osContext.userDetails.id,
      })
      setAppPinned(!appPinned)
      queryClient.invalidateQueries([ApiQueryKeys.TOOLBAR_APPS])
    } catch (e: any) {
      if (e?.response?.data?.detail) {
        showToast({
          message: e.response.data.detail,
          type: 'error',
          duration: 4000,
        })
      }
      console.error(e)
    }
  }

  const [appSaved, saveAppState] = useState<AppAPI | null>(null)
  const osContextRef = useRef(osContext)
  const dataRef = useRef(data)
  const appSavedRef = useRef(appSaved)

  const [iconVisible, setIconVisible] = useState(false)
  const [appPinned, setAppPinned] = useState(isAppPinned)
  const [appHover, setAppHover] = useState(false)

  const handleOpenApp = (app: AppAPI) => {
    if (app.inProjectContext && !osContext.project) {
      showToast({
        message: 'This application can be launched in the project context only',
        type: 'error',
        duration: 4000,
      })
    } else {
      saveAppState(app)
      openApp(app)
    }
  }

  const openApp = async (app: AppAPI) => {
    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: {
        action: EVENTS.ACTIONS.APP_LAUNCH,
        params: [
          { key: 'productName', value: app.name },
          {
            key: 'launchedFrom',
            value:
              type === 'all-apps'
                ? 'AllToolsList'
                : type === 'recommended-apps'
                ? 'AItoolbarMainMenu'
                : 'AItoolbarMainMenu',
          },
          { key: 'productType', value: 'iframe' },
        ],
      },
    })

    try {
      if (isFetched) {
        // Delete unnecessary data from osContext
        const cloneOsContext = JSON.parse(JSON.stringify(osContext))
        delete cloneOsContext.navigationTree
        delete cloneOsContext.theme

        await createEvent({
          context: cloneOsContext,
          userId: osContext.userDetails.id,
          userEmail: osContext.userDetails.email,
          userRole: 'OWNER',
          tenantId: osContext.tenant.id,
          tenantName: osContext.tenant.name,
          hubId: data.length ? data[0].id : '',
          hubName: data.length ? data[0].name : '',
          interactionType: 'tool',
          toolId: app.id,
          toolName: app.name,
          // serviceMention: string,
          // servicePrompt: string,
          // marketId: string,
          // marketName: string,
          // clientId: string,
          // clientName: string,
          // brandId: string,
          // brandName: string,
        })
      }
    } catch (error) {
      console.error('Error opening app', error)
    }

    if (app.inProjectContext) {
      showToast({
        header: 'This tool uses project-specific data',
        message: 'Leaving this project will close the tool overlay and any content there will be lost.',
        type: 'warning',
        duration: 4000,
      })
    }

    osApi.navigation.openCompactApp({
      name: app.name,
      url: app.appUrl,
      overlayProps: {
        id: 'app-toolbar-iframe',
        bounds: 'parent',
        // enableUserSelectHack: false,
        default: {
          x: window.innerWidth - (assistantWidth + 30) - getOverlayWidth(),
          y: 24,
          width: getOverlayWidth(),
          height: window.innerHeight - 63 - 48,
        },
        style: {
          zIndex: 50,
        },
        enableResize: app.resizable || false,
        cancel: '.cancel-drag',
      },
    })
  }

  const getOverlayWidth = () => {
    if (window.innerWidth > 1366) {
      return 760
    }
    return 600
  }

  useEffect(() => {
    osContextRef.current = osContext
    dataRef.current = data
    appSavedRef.current = appSaved
  }, [osContext, data, appSaved])

  const appDisabledProjectContext = useMemo(() => {
    return app.inProjectContext && !osContext.project
  }, [app.inProjectContext, osContext.project])

  const appDisabledExternal = useMemo(() => {
    return (osContext.userDetails as any)?.isExternal && !app.accessibleToExternalUsers
  }, [app.accessibleToExternalUsers, osContext.userDetails])

  const appDisabled = useMemo(() => {
    return appDisabledExternal || appDisabledProjectContext
  }, [appDisabledExternal, appDisabledProjectContext])

  return (
    <>
      {type === 'all-apps' && (
        <WppListItem
          className={clsx(styles.listItem, appDisabled && styles.buttonDisabled, 'cancel-drag')}
          onMouseEnter={() => setIconVisible(true)}
          onMouseLeave={() => setIconVisible(false)}
          onClick={() => !appDisabled && handleOpenApp(app)}
        >
          <Flex key={app.id} slot="label" className={clsx(appDisabled && styles.opacity)}>
            <DynamicIconApp name={app.iconUrl || 'WppIconApp'} slot="icon-start" />
            <WppTypography className={styles.appName} tag="span" type="s-body">
              {app.name}
            </WppTypography>
          </Flex>
          {appPinned && (
            <div slot="right" className={styles.relative}>
              <WppTooltip text="Unpin from toolbar" config={tooltipConfig}>
                {!loading ? (
                  <img
                    src={IconPin}
                    alt=""
                    className={styles.pointer}
                    onClick={(e: any) => {
                      pinApp()
                      e.stopPropagation()
                    }}
                  />
                ) : (
                  <WppSpinner />
                )}
              </WppTooltip>
              {appDisabledProjectContext && iconVisible ? (
                <WppTooltip
                  className={styles.infoIcon}
                  text="This app cannot be launched outside of a project"
                  config={tooltipConfig}
                >
                  <WppIconInfo />
                </WppTooltip>
              ) : null}
            </div>
          )}

          {iconVisible && !appPinned && (
            <div slot="right" className={styles.relative}>
              <WppTooltip text="Pin to toolbar" config={tooltipConfig}>
                {!loading ? (
                  <img
                    src={IconUnpin}
                    alt=""
                    className={styles.pointer}
                    onClick={(e: any) => {
                      pinApp()
                      e.stopPropagation()
                    }}
                  />
                ) : (
                  <WppSpinner />
                )}
              </WppTooltip>

              {appDisabledProjectContext && (
                <WppTooltip
                  className={styles.infoIcon}
                  text="This app cannot be launched outside of a project"
                  config={tooltipConfig}
                >
                  <WppIconInfo />
                </WppTooltip>
              )}
            </div>
          )}
        </WppListItem>
      )}

      {type === 'recommended-apps' && handleMouseEnterCallback && (
        <Fragment key={app.id}>
          {isSidebarExpanded ? (
            <WppActionButton
              className={clsx(
                { [styles.button]: isSidebarExpanded },
                'cancel-drag',
                appDisabled && styles.buttonDisabled,
              )}
              variant="secondary"
              onClick={() => handleOpenApp(app)}
              onMouseEnter={() => {
                setAppHover(true)
                handleMouseEnterCallback()
              }}
              onMouseLeave={() => setAppHover(false)}
              disabled={appDisabled}
            >
              <DynamicIconApp name={app.iconUrl || 'WppIconApp'} slot="icon-start" />
              <WppTypography className={styles.appName} tag="span" type="s-body">
                {app.name}
              </WppTypography>
              <div className={styles.relative}>
                {appDisabledProjectContext && appHover ? (
                  <WppTooltip
                    className={styles.infoIconRecomm}
                    text="This app cannot be launched outside of a project"
                    config={tooltipConfig}
                  >
                    <WppIconInfo className={styles.infoIconRecommIcon} />
                  </WppTooltip>
                ) : null}
              </div>
            </WppActionButton>
          ) : (
            <WppTooltip
              key={app.name}
              className={clsx(styles.conversationItemTooltip, 'cancel-drag')}
              text={app.name}
              config={tooltipConfig}
            >
              <WppActionButton
                className={clsx({ [styles.button]: isSidebarExpanded })}
                variant="secondary"
                onClick={() => handleOpenApp(app)}
                onMouseEnter={() => {
                  handleMouseEnterCallback()
                  setAppHover(true)
                }}
                onMouseLeave={() => setAppHover(false)}
                disabled={appDisabled}
              >
                <DynamicIconApp name={app.iconUrl || 'WppIconApp'} slot="icon-start" />
              </WppActionButton>
            </WppTooltip>
          )}
        </Fragment>
      )}

      {type === 'pinned-apps' && handleMouseEnterCallback && (
        <Fragment key={app.id}>
          {isSidebarExpanded ? (
            <div className={styles.relative}>
              <WppActionButton
                className={clsx({ [styles.button]: isSidebarExpanded }, 'cancel-drag')}
                variant="secondary"
                onClick={() => handleOpenApp(app)}
                onMouseEnter={() => {
                  handleMouseEnterCallback()
                  setAppHover(true)
                }}
                onMouseLeave={() => setAppHover(false)}
                disabled={appDisabled}
              >
                <DynamicIconApp name={app.iconUrl || 'WppIconApp'} slot="icon-start" />
                <>
                  <WppTypography className={styles.appNamePinned} tag="span" type="s-body">
                    {app.name}
                  </WppTypography>

                  <div className={styles.relative}>
                    {appDisabledProjectContext && appHover && (
                      <WppTooltip
                        className={styles.infoIconPinned}
                        text="This app cannot be launched outside of a project"
                        config={tooltipConfig}
                      >
                        <WppIconInfo />
                      </WppTooltip>
                    )}
                  </div>
                </>
              </WppActionButton>
              {loading ? (
                <WppSpinner className={styles.firstIcon} />
              ) : (
                <img
                  src={IconPin}
                  alt=""
                  className={styles.firstIcon}
                  onClick={(e: any) => {
                    pinApp()
                    e.stopPropagation()
                  }}
                />
              )}
            </div>
          ) : (
            <WppTooltip
              key={app.name}
              className={clsx(styles.conversationItemTooltip, 'cancel-drag')}
              text={app.name}
              config={tooltipConfig}
            >
              <WppActionButton
                className={clsx({ [styles.button]: isSidebarExpanded })}
                variant="secondary"
                onClick={() => handleOpenApp(app)}
                onMouseEnter={() => {
                  handleMouseEnterCallback()
                  setAppHover(true)
                }}
                onMouseLeave={() => setAppHover(false)}
                disabled={appDisabled}
              >
                <DynamicIconApp name={app.iconUrl || 'WppIconApp'} slot="icon-start" />
              </WppActionButton>
            </WppTooltip>
          )}
        </Fragment>
      )}
    </>
  )
}
